import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";

const Breve = () => {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto" }}
        >
          <MKBadge variant="contained" container sx={{ mb: 2 }} />
          <MKTypography variant="h2" fontWeight="bold">
            Informations
          </MKTypography>
          <br />
        </Grid>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "center", my: 0, mx: "auto", px: 0 }}
        >
          <MKTypography variant="body1" color="text">
            <b>BOUTIQUE GAMEGEND x PGW 2024</b>
            <br />
            <br />
            Mise à jour du 6 décembre
            <br />
            <br />
            L&apos;association a reçu l&apos;ensemble des produits personnalisés via son
            prestataire.
            <br />
            <br />
            Les commandes seront préparées dès ce week-end.
            <br />
            <br />
            Une fois la commande traitée, vous recevez automatiquement un courriel avec votre numéro
            de suivi.
            <br />
            (si vous avez indiqué une adresse courriel dans votre commande)
            <br />
            <br />
            Merci pour votre patience.
            <br />
            Nous avons hâte que vous receviez tout ça 😊
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default Breve;
